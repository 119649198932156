import React, { useState, useRef } from 'react'
import LayoutEN from 'layouts/en'
import Seo from 'components/EN/Seo'
import HomeBanner from 'components/EN/HomeBanner'
import EnCard from 'components/Solutions/EnCard'
import VideoPlayer from 'components/VideoPlayer'
import { graphql } from 'gatsby'
import * as styles from './index.module.less'
import classnames from 'classnames'

const Solutions = (props) => {
  const { data } = props
  const {
    solutionsEnYaml: { seo, banner, cardList },
  } = data
  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const curVideo = useRef<any>(null)
  const handleClickVideo = (video_source) => {
    setVideoUrl(video_source)
    setTimeout(() => {
      curVideo?.current?.playVideo()
    }, 100)
  }
  return (
    <LayoutEN>
      <Seo {...seo} />
      <main className={styles.solutions}>
        <HomeBanner {...banner} />
        <div className="lg:py-[100px] py-[6rem] w-fit mx-auto px-[2rem] lg:px-0">
          {!!cardList?.length &&
            cardList.map((item, index) => {
              return (
                <EnCard
                  className={classnames({ 'lg:pb-[100px] pb-[60px]': index !== cardList.length - 1 })}
                  {...item}
                  key={item.tag}
                  handleClickVideo={handleClickVideo}
                />
              )
            })}
        </div>
        {videoUrl && (
          <VideoPlayer
            videoOpt={{
              sources: videoUrl,
            }}
            fuRef={curVideo}
            onClose={() => setVideoUrl(null)}
          />
        )}
      </main>
    </LayoutEN>
  )
}

export const query = graphql`
  query {
    solutionsEnYaml {
      seo {
        title
        description
        keywords
      }
      banner {
        title
        desc
      }
      cardList {
        tag
        title
        desc
        buttons {
          button_text
          button_href
          button_type
          video_source
        }
        type
        video_source
        img_url {
          publicURL
        }
        reverse
      }
    }
  }
`

export default Solutions
