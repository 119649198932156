import React from 'react'
import ButtonGroup from 'sensd/lib/button/button-group'
import Button from 'components/Base/Button'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface HomeBannerProps {
  className?: string
  title: string
  desc: string
  buttons?: Array<{ button_text: string; button_href: string; button_type: string; button_class?: string }>
  video_img?: any
  clickVideo?: () => void
}

const HomeBanner: React.FC<HomeBannerProps> = (props) => {
  const { className = '', title, desc, buttons, video_img, clickVideo } = props
  const handleClickVideo = () => {
    clickVideo?.()
  }
  return (
    <div className={classnames(styles.banner, className)}>
      <div className="en-banner-wrapper">
        <h1 className="en-banner-title">{title}</h1>
        <p className="en-banner-desc">{desc}</p>
        {!!buttons?.length && (
          <ButtonGroup className="en-banner-buttons">
            {buttons?.map((item) => {
              return (
                <Button
                  key={item.button_text}
                  className={item?.button_class}
                  btnType={item.button_type}
                  href={item.button_href}
                >
                  {item.button_text}
                </Button>
              )
            })}
          </ButtonGroup>
        )}
        {!!video_img && (
          <div className="en-banner-video" onClick={handleClickVideo}>
            <img className="en-banner-video-img" src={video_img?.publicURL || video_img} alt={title} />
          </div>
        )}
      </div>
    </div>
  )
}

export default HomeBanner
