import React from 'react'
import Button from 'components/Base/Button'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface EnCardProps {
  className?: string
  tag: string
  title: string
  desc: string
  buttons: {
    button_text: string
    button_href?: string
    button_type: string
    video_source?: string
    disabled?: boolean
  }[]
  type: string
  img_url: string | { publicURL: string }
  reverse?: boolean
  video_source?: string
  handleClickVideo?: (video_source: string) => string
  coming_soon?: boolean
}

const imgMb = (img_url, type, handleClickVideo, video_source, tag) => {
  const handleClick = () => {
    type === 'video' && handleClickVideo?.(video_source)
  }
  return (
    <div
      className={classnames('mt-[18px] lg:mt-0  rounded-[18px]', { 'en-card-video-mask': type === 'video' })}
      onClick={handleClick}
    >
      <img
        className="lg:w-[540px] lg:h-[304px] w-[100%] rounded-[18px]"
        src={(img_url as any)?.publicURL || img_url}
        alt={tag}
      />
    </div>
  )
}
const EnCard: React.FC<EnCardProps> = (props) => {
  const {
    className = '',
    tag,
    title,
    type,
    desc,
    buttons,
    img_url,
    reverse = false,
    video_source = '',
    handleClickVideo,
    coming_soon,
  } = props
  const isMb = useIsWindowWidthSmaller()
  return (
    <div className={classnames(styles.EnCard, 'lg:flex items-center', { 'flex-row-reverse': reverse }, className)}>
      <div className={classnames('lg:w-[550px] ', { 'lg:mr-[46px]': !reverse, 'lg:ml-[46px]': reverse })}>
        <h2 className={classnames('en-card-tag ', { 'en-card-tag-nameplate': coming_soon })}>{tag}</h2>
        <h3 className="en-card-title">{title}</h3>
        <p className="en-card-desc">{desc}</p>
        {isMb && imgMb(img_url, type, handleClickVideo, video_source, tag)}
        <div className="lg:mt-[34px] mt-[26px] ">
          {buttons?.map(({ button_text, button_href = '', button_type, video_source, disabled }, index) => {
            return (
              <Button
                className={classnames({
                  'mt-[10px] lg:mt-0 lg:ml-[22px]': index === 1,
                  'hover:bg-[#fff] cursor-default': disabled,
                })}
                btnType={button_type}
                key={button_text}
                href={button_href}
                onClick={() => video_source && handleClickVideo?.(video_source)}
              >
                {button_text}
              </Button>
            )
          })}
        </div>
      </div>
      {!isMb && imgMb(img_url, type, handleClickVideo, video_source, tag)}
    </div>
  )
}
export default EnCard
